@charset "UTF-8";
@font-face {
  font-family: 'stack-interface';
  src: url("../fonts/stack-interface.eot%3F33839631");
  src: url("../fonts/stack-interface.eot%3F33839631") format("embedded-opentype"), url("../fonts/stack-interface.woff2%3F33839631") format("woff2"), url("../fonts/stack-interface.woff%3F33839631") format("woff"), url("../fonts/stack-interface.ttf%3F33839631") format("truetype"), url("../fonts/stack-interface.svg%3F33839631") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'stack-interface';
    src: url('../font/stack-interface.svg?34857618#stack-interface') format('svg');
  }
}
*/
[class^="stack-"]:before, [class*=" stack-"]:before {
  font-family: "stack-interface";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.stack-down-open-big:before {
  content: '\e800'; }

/* '' */
.stack-left-open-big:before {
  content: '\e801'; }

/* '' */
.stack-right-open-big:before {
  content: '\e802'; }

/* '' */
.stack-up-open-big:before {
  content: '\e803'; }

/* '' */
.stack-basket:before {
  content: '\e804'; }

/* '' */
.stack-search:before {
  content: '\e805'; }

/* '' */
.stack-down-dir:before {
  content: '\e806'; }

/* '' */
.stack-left-dir:before {
  content: '\e807'; }

/* '' */
.stack-right-dir:before {
  content: '\e808'; }

/* '' */
.stack-up-dir:before {
  content: '\e809'; }

/* '' */
.stack-down-open:before {
  content: '\e80a'; }

/* '' */
.stack-left-open:before {
  content: '\e80b'; }

/* '' */
.stack-right-open:before {
  content: '\e80c'; }

/* '' */
.stack-up-open:before {
  content: '\e80d'; }

/* '' */
.stack-menu:before {
  content: '\e80e'; }

/* '' */
.stack-users:before {
  content: '\e80f'; }

/* '' */
.stack-publish:before {
  content: '\e810'; }

/* '' */
.stack-trash:before {
  content: '\e811'; }

/* '' */
.stack-bell:before {
  content: '\e812'; }

/* '' */
.stack-cog:before {
  content: '\e813'; }

/* '' */
.stack-plus-circled:before {
  content: '\e814'; }

/* '' */
.stack-dot-3:before {
  content: '\e815'; }

/* '' */
